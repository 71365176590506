// 当前角色的信息
import * as THREE from "three";
class initPlayer {
	constructor() {
		this.player = null;//主控人物的所有东西(模型,动画等)
		this.runclip =null;//跑的判断
		this.stopclip = null;//
		this.mixer =null;//主控模型绑定的混入器
		this.clip =null;//当前的动画片段
		this.newaction =null;
	}
	 // 加载主控人物
	loadplayermodel(playermodel) {
		console.log(playermodel)
		this.player = playermodel
		this.clips =  playermodel.animations;
		this.mixer = new THREE.AnimationMixer(playermodel.scene);
		this.stop()
	}
	// 控制主控人物
	walk(){
		if(this.clipname &&	this.clipname == 'Animation') return
		let clip = THREE.AnimationClip.findByName( this.clips, 'Animation' );
		let newaction =  this.mixer.clipAction( clip );
		newaction.play();
		this.newaction &&this.newaction.stop()
		this.newaction = newaction
		this.clipname = 'Animation'
	}
	stop(){
		if(this.clipname &&	this.clipname == 'Animation_Idle') return
		let clip = THREE.AnimationClip.findByName( this.clips, 'Animation_Idle' );
		let newaction =  this.mixer.clipAction( clip );
		newaction.play();
		this.newaction &&this.newaction.stop()
		this.newaction = newaction
		this.clipname = 'Animation_Idle'
		
	}
	update(delta){
		this.mixer.update(delta );
	}
	
	// 切换页面时销毁
	deletemixer(){
		
	}
}
export default new initPlayer()
