/* eslint-disable */
import * as THREE from "three";
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import initPlayer from './initPlayer.js'
import {
	RGBELoader
} from 'three/examples/jsm/loaders/RGBELoader.js'
import {
	GLTFLoader
} from "three/examples/jsm/loaders/GLTFLoader.js";
import {
	DRACOLoader
} from "three/examples/jsm/loaders/DRACOLoader";
import {
	OrbitControls
} from "three/examples/jsm/controls/OrbitControls.js";
//import Stats from "three/examples/jsm/libs/stats.module.js";
import {
	GUI
} from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js';
// 主控人物形象地址
let playerurl = "https://qiniu.argamemap.com/arweb-showroom/model/gltf0824/model1/model1/Boy_1.gltf"
import {
	MeshBVHVisualizer,
	MeshBVH
} from 'three-mesh-bvh';

import {
	TweenMax
} from "gsap";

const params = {

	firstPerson: true,

	displayCollider: false,
	displayBVH: false,
	visualizeDepth: 10,
	gravity: -30,
	playerSpeed:3,
	physicsSteps: 1,

};

let renderer, camera, scene, clock, gui;
let cubeCamera, cubeRenderTarget;
//let stats;
let environment, collider, visualizer, player, controls;
let playerIsOnGround = false;
let fwdPressed = false,
	bkdPressed = false,
	lftPressed = false,
	rgtPressed = false;
let playerVelocity = new THREE.Vector3();
let upVector = new THREE.Vector3(0, 1, 0);
let tempVector = new THREE.Vector3();
let tempVector2 = new THREE.Vector3();
let tempBox = new THREE.Box3();
let tempMat = new THREE.Matrix4();
let tempSegment = new THREE.Line3();
let timeMouseDown = 0;
let tweenMove = null;
// let playershowmodel = null; //表明上的人物形象
export default class initThree {
	constructor({
		el: el
	}) {
		this.el = el;
		this.width = this.el.clientWidth;
		this.height = this.el.clientHeight;
		// 射线
		this.raycaster = new THREE.Raycaster();
		// 鼠标
		this.mouse = new THREE.Vector2();

		this.pointMesh = null; // 点击地面出现的圆点

		this.isMove = false; // 是否在移动过程中

		this.model01 = null;

		this.signGroup = [];
		
		this.playershowmodel =null;
	}

	// 初始化场景
	async initStage() {

		// 渲染器
		renderer = new THREE.WebGLRenderer({
			antialias: true
		});
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(this.width, this.height);
		renderer.setClearColor(0x000000, 1);
		renderer.outputEncoding = THREE.sRGBEncoding;
		renderer.toneMapping = THREE.NoToneMapping;
		renderer.toneMappingExposure = 0.85;
		this.el.appendChild(renderer.domElement);

		// 场景
		scene = new THREE.Scene();

		// 灯光
		const light = new THREE.AmbientLight(0xffffff, 0.6);
		scene.add(light);

		// 相机
		camera = new THREE.PerspectiveCamera(60, this.width / this.height, 0.1, 10000);
		camera.position.set(0, 0, 20);
		camera.updateProjectionMatrix();
		window.camera = camera;

		clock = new THREE.Clock();

		// 初始化点击point
		let pointGeo = new THREE.PlaneGeometry(1, 1);
		let pointMat = new THREE.MeshBasicMaterial({
			map: new THREE.TextureLoader().load("/img/point.png"),
			side: 2,
			transparent: true,
			depthWrite: false
		})
		this.pointMesh = new THREE.Mesh(pointGeo, pointMat);

		this.pointMesh.visible = false;
		this.pointMesh.renderOrder = 9;
		this.pointMesh.rotateX(-Math.PI / 2);
		scene.add(this.pointMesh)

		// 控制器
		controls = new OrbitControls(camera, renderer.domElement);

		// hdr 
		await this.initSky("https://qiniu.argamemap.com/arweb-art/hdr/small_harbor_01_1k.hdr");

		// 模型
		// this.model01 = await this.loadDraco("https://qiniu.argamemap.com/arweb-art/model/2.gltf");
		// scene.add(this.model01);

		// let model = await this.loadDraco("https://qiniu.argamemap.com/arweb-art/model/1.gltf");
		// this.initBvh(model);

		// 标签
		// this.loadSign();

		// 性能检测
		//stats = new Stats();
		//this.el.appendChild(stats.dom);

		// 创建gui
		// this.initGui();
		this.loadplayer()
		// 窗口自适应监听
		window.addEventListener('resize', () => {
			this.width = this.el.clientWidth;
			this.height = this.el.clientHeight;

			renderer.setSize(this.width, this.height);
			camera.aspect = this.width / this.height;
			camera.updateProjectionMatrix();
		}, false);

		// wasd事件监听
		window.addEventListener('keydown', (e) => {
			//if (!this.isMove) {
			this.stopMovePlayer();

			switch (e.code) {
				case 'KeyW':
					fwdPressed = true;
					break;
				case 'KeyS':
					bkdPressed = true;
					break;
				case 'KeyD':
					rgtPressed = true;
					break;
				case 'KeyA':
					lftPressed = true;
					break;
				case 'Space':
					if (playerIsOnGround) {
						playerVelocity.y = 10.0;
					}
					break;
			}
			//}
		});

		window.addEventListener('keyup', (e) => {
			//if (!this.isMove) {
			switch (e.code) {
				case 'KeyW':
					fwdPressed = false;
					break;
				case 'KeyS':
					bkdPressed = false;
					break;
				case 'KeyD':
					rgtPressed = false;
					break;
				case 'KeyA':
					lftPressed = false;
					break;
			}
			//}
		});

		// 监听点击事件
		renderer.domElement.addEventListener("mousedown", this.onMouseDown.bind(this), false);
		renderer.domElement.addEventListener("mouseup", this.onMouseUp.bind(this), false);
	}
	// 加载人物
	async loadplayer() {
		player = new THREE.Mesh(
			new RoundedBoxGeometry(0.5, 1.7, 0.5, 10, 0.5),
			new THREE.MeshStandardMaterial({opacity:0,transparent:true})
		)
		player.capsuleInfo = {
			radius: 0.5,
			segment: new THREE.Line3(new THREE.Vector3(), new THREE.Vector3(0, 1.8, 0.0))
		}
		player.name = 'player'
		scene.add(player);
		this.playershowmodel = await this.loadDraco(playerurl,(gltf)=>{
			initPlayer.loadplayermodel(gltf)
		});
		// this.playershowmodel.scale.set(5, 5, 5)
		scene.add(this.playershowmodel);
		this.reset();
	}
	// 初始化相机及角色信息
	reset() {
		playerVelocity.set(0, 0, 0);
		player.position.set(7.8,0.3,8.8);
		camera.position.sub(controls.target);
		controls.target.copy(player.position);
		camera.position.add(player.position);
		this.switchThirdPerson()
	}
	// 切换第一人称
	switchFirstPerson() {
		controls.maxPolarAngle = Math.PI;
		controls.minDistance = 1e-4;
		controls.maxDistance = 1e-4;
		// controls.update();
		this.playershowmodel.visible = false
		this.person = true

	}
	// 切换第三人称
	switchThirdPerson() {
		// controls.maxPolarAngle = Math.PI / 2;
		controls.minDistance = 4;
		controls.maxDistance = 4;
		// controls.update();
		// camera.position.sub(controls.target).normalize().multiplyScalar(4).add(controls.target);
		// camera.position.y += 1.6
		this.playershowmodel.visible = true
		this.person = false
	}
	// 创建gui
	initGui() {
		gui = new GUI();
		gui.add(params, 'firstPerson').name("第一人称").onChange(v => {
			if (!v) {
				camera
					.position
					.sub(controls.target)
					.normalize()
					.multiplyScalar(20)
					.add(controls.target);
			}
		});
		const visFolder = gui.addFolder('Visualization');
		visFolder.add(params, 'displayCollider').name("碰撞体线框");
		visFolder.add(params, 'displayBVH');
		visFolder.add(params, 'visualizeDepth', 1, 20, 1).onChange(v => {
			visualizer.depth = v;
			visualizer.update();
		});
		const physicsFolder = gui.addFolder('Player');
		physicsFolder.add(params, 'physicsSteps', 0, 30, 1).name("步长");
		physicsFolder.add(params, 'gravity', -100, 100, 0.01).name("重力").onChange(v => {
			params.gravity = parseFloat(v);
		});
		physicsFolder.add(params, 'playerSpeed', 1, 60).name("角色控制速度");
		physicsFolder.open();
		gui.open();
	}

	/**
	 * 加载hdr背景及环境贴图
	 * @param {*} url 
	 * @returns 
	 */
	initSky(url) {
		const loader = new RGBELoader();
		let pmremGenerator = new THREE.PMREMGenerator(renderer);
		pmremGenerator.compileEquirectangularShader();

		return new Promise((resolve) => {
			loader.load(url, texture => {
				let envMap = pmremGenerator.fromEquirectangular(texture).texture;
				scene.background = envMap; // 设置为背景
				scene.environment = envMap; // 设置为环境贴图
				texture.dispose();
				pmremGenerator.dispose();
				resolve()
			})
		})
	}
	// 摇杆移动
	joystickMove(e) {
		this.stopMovePlayer();

		fwdPressed = e.moveForward;
		bkdPressed = e.moveBackward;
		rgtPressed = e.moveRight;
		lftPressed = e.moveLeft;
	}
	joystickMoveEnd() {
		fwdPressed = false;
		bkdPressed = false;
		rgtPressed = false;
		lftPressed = false;
	}
	/**
	 * 加载draco模型
	 * 
	 * @param {*} modelPath
	 * @returns gltf
	 */
	loadDraco(modelPath,callback) {
		if (!this.dracoLoader) {
			this.dracoLoader = new DRACOLoader();
		}
		if (!this.gltfLoader) {
			this.gltfLoader = new GLTFLoader();
		}
		this.dracoLoader.setDecoderPath("/draco/");
		this.gltfLoader.setDRACOLoader(this.dracoLoader);
		return new Promise((resolve) => {
			this.gltfLoader.load(modelPath, (gltf) => {
				callback&&callback(gltf)
				resolve(gltf.scene);
			});
		});
	}
	// 有进度条的加载模型
	loadDracoProgress(modelPath, onLoad, onProgress) {
		let loadManager = new THREE.LoadingManager();
		loadManager.onProgress = onProgress;
		let gltfLoader_Draco = new GLTFLoader(loadManager);
		if (!this.dracoLoader) {
			this.dracoLoader = new DRACOLoader();
			this.dracoLoader.setDecoderPath("/draco/");
		}
		gltfLoader_Draco.setDRACOLoader(this.dracoLoader);
		gltfLoader_Draco.load(modelPath, onLoad);
	}
	// 合并几何
	mergegeometry(model) {
		const toMerge = {};
		model.updateMatrixWorld(true);
		// 把颜色相同的
		model.traverse(c => {
			if (c.isMesh) {
				const hex = c.material.name;
				toMerge[hex] = toMerge[hex] || [];
				toMerge[hex].push(c);
			}
		});
		let environment = new THREE.Group();
		for (const hex in toMerge) {
			const arr = toMerge[hex];
			const visualGeometries = [];
			arr.forEach(mesh => {
				// console.log(/guanggao/.test(mesh.name),mesh.name)
				// 不是商品
				if (/sl_way/.test(mesh.name) || mesh.geometry.attributes.uv2) {
					environment.attach(mesh);
				} else {
					const geom = mesh.geometry.clone();
					geom.applyMatrix4(mesh.matrixWorld);
					visualGeometries.push(geom);
				}

			});
			if (visualGeometries.length) {
				// console.log(visualGeometries)
				const newGeom = BufferGeometryUtils.mergeBufferGeometries(visualGeometries);
				const newMesh = new THREE.Mesh(newGeom, arr[0].material);
				environment.add(newMesh);
			}

		}
		environment.updateMatrixWorld(true);
		return environment
	}
	// 批量创建路标
	loadSign() {
		let data = [{
				name: "路标01",
				position: [15.7, 1.6, 9]
			},
			{
				name: "路标02",
				position: [15.598380601609943, 1.6, -25.156436917637407]
			},
			{
				name: "路标03",
				position: [6.249347860476298, 1.6, -21.017217315237286],
				rotate: -Math.PI / 2
			},
			{
				name: "路标04",
				position: [-9.753000180657992, 1.2, -21.939100015766797],
				rotate: -Math.PI / 2
			},
			{
				name: "路标05",
				position: [-9.814249489593593, 1.6, -2.7785870177733045]
			}
		];

		let geo = new THREE.PlaneGeometry(1, 1);
		let mat = new THREE.MeshBasicMaterial({
			color: "red",
			side: 2,
			map: new THREE.TextureLoader().load("/img/logo.png"),
			transparent: true
		})
		let mesh = new THREE.Mesh(geo, mat);
		data.forEach(e => {
			let meshSign = mesh.clone();
			meshSign.name = e.name;
			meshSign.position.set(e.position[0], e.position[1], e.position[2]);
			if (e.rotate) {
				meshSign.rotateY(e.rotate)
			}
			scene.add(meshSign);
			this.signGroup.push(meshSign)
		})
	}

	// BVH碰撞
	initBvh(model) {
		environment = new THREE.Group();
		environment.add(model);
		const geometries = [];
		environment.updateMatrixWorld(true);
		environment.traverse(c => {

			if (c.geometry) {

				const cloned = c.geometry.clone();
				cloned.applyMatrix4(c.matrixWorld);
				for (const key in cloned.attributes) {

					if (key !== 'position') {
						cloned.deleteAttribute(key);
					}

				}
				geometries.push(cloned);
			}

		});

		cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256);
		cubeRenderTarget.texture.type = THREE.HalfFloatType;
		// cubeCamera = new THREE.CubeCamera(1, 1000, cubeRenderTarget);

		// var meshPlane = environment.getObjectByName('sl_way');

		// var color = meshPlane.material.color;
		// var map = meshPlane.material.map;
		// var material = new THREE.MeshStandardMaterial({ color:color, map:map, envMap:cubeRenderTarget.texture, transparent:true });
		// material.roughness = 0.1;
		// material.metalness = 1;
		// material.opacity = 0.2;

		// meshPlane.material = material;

		// 合并检测场景
		const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries, false);
		mergedGeometry.boundsTree = new MeshBVH(mergedGeometry, {
			lazyGeneration: false
		});

		// 线框
		collider = new THREE.Mesh(mergedGeometry);
		collider.material.wireframe = true;
		collider.material.opacity = 0.5;
		collider.material.transparent = true;

		// 创建bvh检测
		visualizer = new MeshBVHVisualizer(collider, params.visualizeDepth);
		scene.add(visualizer);
		scene.add(collider);
		scene.add(environment);
		collider.name = "主体场景"
	}



	// 更新角色移动视角处理
	updatePlayer(delta) {
		playerVelocity.y += playerIsOnGround ? 0 : delta * params.gravity;
		player.position.addScaledVector(playerVelocity, delta);
		
		// move the player
		const angle = controls.getAzimuthalAngle();
			
		if(!this.person && !this.playershowmodel.visible && !this.isMove ){
			this.playershowmodel.visible = true
		}
		if (fwdPressed) {
			this.playershowmodel.rotation.y = angle - Math.PI
			tempVector.set(0, 0, -1).applyAxisAngle(upVector, angle);
			player.position.addScaledVector(tempVector, params.playerSpeed * delta);

		}

		if (bkdPressed) {
			this.playershowmodel.rotation.y = angle
			tempVector.set(0, 0, 1).applyAxisAngle(upVector, angle);
			player.position.addScaledVector(tempVector, params.playerSpeed * delta);

		}

		if (lftPressed) {
			this.playershowmodel.rotation.y = angle - Math.PI * 0.5
			tempVector.set(-1, 0, 0).applyAxisAngle(upVector, angle);
			player.position.addScaledVector(tempVector, params.playerSpeed * delta);

		}

		if (rgtPressed) {
			this.playershowmodel.rotation.y = angle + Math.PI * 0.5
			tempVector.set(1, 0, 0).applyAxisAngle(upVector, angle);
			player.position.addScaledVector(tempVector, params.playerSpeed * delta);

		}
		// 其他
		if (fwdPressed&&lftPressed) {
			this.playershowmodel.rotation.y = angle - Math.PI *0.75
		}
		if (fwdPressed&&rgtPressed) {
			this.playershowmodel.rotation.y =  angle + Math.PI * 0.75
		}
		
		if (rgtPressed&&bkdPressed) {
			this.playershowmodel.rotation.y = angle + Math.PI * 0.25
		}
		if (lftPressed&&bkdPressed) {
			this.playershowmodel.rotation.y = angle - Math.PI * 0.25
		}	
		
		if (rgtPressed || lftPressed || bkdPressed || fwdPressed ) {
			initPlayer.walk()
		} else {
			initPlayer.stop()
		}
		// 手动更新角色矩阵信息
		player.updateMatrixWorld();

		// 根据碰撞检测  调整角色位置
		const capsuleInfo = player.capsuleInfo;
		tempBox.makeEmpty();
		tempMat.copy(collider.matrixWorld).invert();
		tempSegment.copy(capsuleInfo.segment);

		// 获取角色再bvh检测空间中位置矩阵信息
		tempSegment.start.applyMatrix4(player.matrixWorld).applyMatrix4(tempMat);
		tempSegment.end.applyMatrix4(player.matrixWorld).applyMatrix4(tempMat);

		// 获取角色边界信息
		tempBox.expandByPoint(tempSegment.start);
		tempBox.expandByPoint(tempSegment.end);

		tempBox.min.addScalar(-capsuleInfo.radius);
		tempBox.max.addScalar(capsuleInfo.radius);

		collider.geometry.boundsTree.shapecast({

			intersectsBounds: box => box.intersectsBox(tempBox),

			intersectsTriangle: tri => {

				// 角色位置修复
				const triPoint = tempVector;
				const capsulePoint = tempVector2;

				const distance = tri.closestPointToSegment(tempSegment, triPoint, capsulePoint);
				if (distance < capsuleInfo.radius) {

					const depth = capsuleInfo.radius - distance;
					const direction = capsulePoint.sub(triPoint).normalize();

					tempSegment.start.addScaledVector(direction, depth);
					tempSegment.end.addScaledVector(direction, depth);
				}
			}
		});


		const newPosition = tempVector;
		newPosition.copy(tempSegment.start).applyMatrix4(collider.matrixWorld);

		// 获取移动距离
		const deltaVector = tempVector2;
		deltaVector.subVectors(newPosition, player.position);

		// 
		playerIsOnGround = deltaVector.y > Math.abs(delta * playerVelocity.y * 0.25);

		const offset = Math.max(0.0, deltaVector.length() - 1e-5);
		deltaVector.normalize().multiplyScalar(offset);

		// 调整角色信息
		player.position.add(deltaVector);

		if (!playerIsOnGround) {

			deltaVector.normalize();
			playerVelocity.addScaledVector(deltaVector, -deltaVector.dot(playerVelocity));

		} else {

			playerVelocity.set(0, 0, 0);

		}
		camera.position.sub(controls.target);
		controls.target.copy(player.position);
		controls.target.y += 1.6;
		camera.position.add(controls.target);
		
		// cubeCamera.position.copy(camera.position);

		// 角色低于地面高度  重置
		if (player.position.y < -25) {
			this.reset();
		}
	}

	render() {
		//stats.update();
		requestAnimationFrame(() => {
			this.render();
		});

		// if (this.signGroup.length != 0) {
		// 	this.signGroup.forEach(ele => {
		// 		const time = Date.now() * 0.001
		// 		ele.position.y = Math.cos(time) * 0.75 + 1.5
		// 	})
		// }
		const delta = Math.min(clock.getDelta(), 0.1);
		if (collider) {
			collider.visible = params.displayCollider;
			visualizer.visible = params.displayBVH;
			initPlayer.update(delta)
			// console.log(player.position.x, player.position.y - 0.5, player.position.z)
			this.playershowmodel.position.set(player.position.x, player.position.y - 0.5, player.position.z)
			const physicsSteps = params.physicsSteps;
			for (let i = 0; i < physicsSteps; i++) {
				this.updatePlayer(delta / physicsSteps);
			}
		}
		controls&&controls.update();
		// cubeCamera.update(renderer, scene);
		renderer.render(scene, camera);
		// if (this.model01) this.model01.rotateY(0.005);
	}

	// 鼠标按下
	onMouseDown() {
		timeMouseDown = clock.getElapsedTime();
	}

	// 鼠标抬起
	onMouseUp(e) {
		let timeMouseUp = clock.getElapsedTime()
		if (timeMouseUp - timeMouseDown < 0.15) {
			this.getModel(e);
		}
	}

	// 射线拾取
	getModel(e) {
		this.getMousePosition(e);

		this.raycaster.setFromCamera(this.mouse, camera);
		let intersects = this.raycaster.intersectObjects([environment], true);
		if (intersects.length > 0) {
			// 射线穿过模型
			let chooseMesh = intersects[0];
			//console.log(chooseMesh)

			let judge = chooseMesh.object.name;
			// 判断点击内容
			// 地面 移动角色
			if (judge.indexOf("sl_way") != -1) {
				// 显示地标
				this.pointMesh.position.set(chooseMesh.point.x, chooseMesh.point.y + 0.1, chooseMesh.point
				.z); //+0.1避免闪烁问题
				this.pointMesh.visible = true;

				// 补间动画 修改角色位置信息  1.5s完成补间动画
				this.movePlayer(1.5, {
					x: chooseMesh.point.x,
					z: chooseMesh.point.z,
					onStart: () => {
						//移动过程中禁止键盘操作  避免bug
						this.isMove = true;
						if(!this.person){
							this.playershowmodel.visible = false
						}
					},
					onComplete: () => {
						this.pointMesh.visible = false;
						this.isMove = false;
						if(!this.person){
							this.playershowmodel.visible = true
						}
					}
				})
			} else if (judge.indexOf("sl_picture") != -1) {
				// 画框
				alert("这是展品" + chooseMesh.object.name)
			}

		} else {
			this.pointMesh.visible = false;
		}
	}

	// 移动
	movePlayer(time, optation) {
		tweenMove = TweenMax.to(player.position, time, optation)
	}
	// 停止移动
	stopMovePlayer() {
		if (tweenMove) {
			tweenMove.kill();
			this.pointMesh.visible = false;
			this.isMove = false
		}
	}

	// 平面坐标转换
	getMousePosition(e) {
		e.preventDefault();
		this.mouse.x = ((e.clientX - this.el.offsetLeft) / this.el.clientWidth) * 2 - 1;
		this.mouse.y = -((e.clientY - this.el.offsetTop) / this.el.clientHeight) * 2 + 1;
	}

}