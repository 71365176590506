import * as THREE from "three";

// -------------------------- 计算摄像机的角度 -------------------------

function getCameraAngle(camera) {
    if (!camera) {
        return;
    }
    const e = camera.getWorldDirection(new THREE.Vector3());
    const t = (new THREE.Vector2()).subVectors(new THREE.Vector2(e.z, e.x), new THREE.Vector2(0, -1));
    t.y = t.y - 1;
    const i = t.length();
    let r = Math.acos(t.x / i);
    t.y < 0 && (r *= -1);
    r = - (180 * r / Math.PI + 180) + 180;
    r = (r < 0 ? (r + 360) : r) % 360;
    return r;
}

// -------------------------- 计算摄像机的位置 -------------------------

function getCameraPosition(camera) {
    let res = { x: 0, y: 0, z: 0 };
    if (camera && camera.position) {
        res.x = camera.position.x;
        res.y = camera.position.y;
        res.z = camera.position.z;
    }
    return res;
}

// --------------------------------------------------------------------

export { getCameraAngle, getCameraPosition };