<template>
	<!-- <div 
    id="canvas_3d" 
	class="blocks"
	v-loading="loading"
	element-loading-text="loading..."
	element-loading-spinner="el-icon-loading"
	element-loading-background="rgba(0, 0, 0, 0)"
  > -->
	<div id="canvas_3d" class="blocks">
		<!-- 按钮 -->
		<!-- <div class="allbtn">
			<div @click="changeperson">{{person?'切换第三人称':'切换第一人称'}}</div>
		</div> -->
		<!-- 加载 v-if="loading"-->
		<div class="load" v-if="!isenter" >
			<!-- 背景图 -->
			<img class="banner" src="https://qiniu.argamemap.com/arweb-showroom/images/loadbg1.jpg" alt="">
			<!-- 进度条 -->
			<div class="load-text" v-if="loadnum != 100">
				<div class="text">
					loading...{{loadnum+'%'}}
				</div>
				<div class="load-progress">
					<img class="loadingbg" src="/img/loadingbg.png" alt="">
					<div class="loadwidth" :style="{width:loadnum +'%'}">
						<img class="loadingfill" src="/img/loadingfill.png" alt="">
					</div>
				</div>
			</div>
			<!-- 点击进入 -->
			<div class="load-text" @click="isenter = true" v-else>
				<img class="entergame" src="/img/jinrubt.png" alt="">
			</div>
		</div>
		<!-- <p class="tips" v-show="!loading">WASD控制移动，拖动控制方向，点击地面行走</p> -->
		<div class="map_container" v-show="!loading">
			<img class="map" ref="map" src="@/assets/imgs/map/地图.png" />
			<img class="figure" ref="figure" src="@/assets/imgs/map/人物图标.png" />
		</div>
		<!-- 对话框 -->
		<div class="dialog-box">
			<img src="/img/listitembg.png" alt="">
			<input type="text">
		</div>
	<!-- 	
		<ul class="maplist" v-show="!loading">
			<li v-for="(item, index) of mapList" :key="item.name" @click="handleClick(index)">
				<img :src="item.imgUrl" alt="" />
			</li>
		</ul> 
	-->
		<!-- 移动端的摇杆 -->
		<PeachJoystick v-if="!loading&&!result" @moveant="moveant" @move="move" />
	</div>
</template>

<script>
	// import 'element-ui/lib/theme-chalk/index.css';
	import initThree from "./initThree";
	import {
		getCameraAngle,
		getCameraPosition
	} from "./compass";
	import PeachJoystick from '@/components/joystick/index.vue'
	export default {
		name: "HelloWorld",
		components: {
			PeachJoystick
		},
		data() {
			return {
				person:true,//true 第一人称  false 第三人称
				result: false,
				loading: true,
				loadnum:0,//进度条
				isenter:false,
				imgIndex: 99,
				modelurl:'https://qiniu.argamemap.com/arweb-showroom/model/gltf_0825/model2/Luckin.gltf',
				model: {
					x: 277 * 2,
					z: 277 * 2,
				},
				map: {
					width: 120,
					height: 120,
				},
				figure: {
					width: 8,
					height: 16,
				},
				mapList: [
					{
						name: "Point1",
						position: [0, 16, 119],
						imgUrl: require("../assets/imgs/1.jpg"),
					},
					{
						name: "Point2",
						position: [-198, 16, 30],
						imgUrl: require("../assets/imgs/2.jpg"),
					},
					{
						name: "Point3",
						position: [-109, 16, -216],
						imgUrl: require("../assets/imgs/3.jpg"),
					},
				],
			};
		},
		computed: {
			ratio() {
				return {
					x: this.model.x / this.map.width,
					z: this.model.z / this.map.height,
				};
			},
		},
		methods: {
			async init() {
				const viewer = new initThree({
					el: document.getElementById("canvas_3d"),
				});
				window.viewer = viewer;
				await viewer.initStage();
				viewer.loadDracoProgress(this.modelurl,(gltf)=>{
					// let model = viewer.mergegeometry(gltf.scene)
					// gltf.scene.scale.set(10,10,10)
					viewer.initBvh(gltf.scene);
					this.loading = false;
					viewer.render();
				},
				(assetsUrl, num, total) => {
					let percent = Math.floor((num/total)*100) ;
					this.loadnum = percent
				})
			},
			handleClick(index) {
				console.log(index);
				// 移动角色位置
				window.viewer.movePlayer(2, {
					x: this.mapList[index].position[0],
					z: this.mapList[index].position[2],
					onStart: () => {
						if(!this.person){
						    window.viewer.isMove = true
						   window.viewer.playershowmodel.visible = false
						}
					},
					onComplete: () => {
						if(!this.person){
							 window.viewer.isMove = false
							window.viewer.playershowmodel.visible = true
						}
					}
				});
				// 修改相机旋转角度
			},
			changeperson(){
				this.person = !this.person
				if(this.person){
					window.viewer.switchFirstPerson()
				}else{
					window.viewer.switchThirdPerson()
				}
				
				
			},
			//摇杆事件
			move(e) {
				//console.log(e)
				window.viewer.joystickMove(e);
			},
			// 摇杆回到中心
			moveant() {
				window.viewer.joystickMoveEnd();
			},
			// 点赞
		},
		mounted() {
			this.result = !navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i); //如果为true用pc端
			setTimeout(() => {
				this.init();
				// 更新地图相机
				setInterval(() => {
					let left =
						getCameraPosition(window.camera).x / this.ratio.x +
						this.map.width / 2;
					let top =
						getCameraPosition(window.camera).z / this.ratio.z +
						this.map.height / 2;
					const angle = getCameraAngle(window.camera);
					const figure = this.$refs.figure;
					left -= this.figure.width / 2;
					top -= this.figure.height / 2;
					figure.style.left = `${left}px`;
					figure.style.top = `${top}px`;
					figure.style.transform = `rotate(${angle}deg)`;
				}, 10);
			}, 50);
		},
	};
</script>
<style scoped>
	@media screen and (min-width: 951px) {
		.allbtn{
			position: absolute;
			top: 10px;
			right: 10px;
		}
		.allbtn div{
			background-color: white;
			color: #666;
			border-radius: 5px;
			padding: 5px 10px;
		}
		.blocks {
			width: 100%;
			height: 100%;
			/* background: url("../assets/background.jpg") no-repeat center; */
			background-size: cover;
			position: relative;
		}

		.tips {
			position: absolute;
			top: 5px;
			left: 50%;
			width: 360px;
			margin-left: -180px;
			color: #666;
		}

		.map_container {
			width: 120px;
			height: 120px;
			position: absolute;
			top: 0;
			left: 0;
		}

		.map {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.figure {
			width: 8px;
			height: 16px;
			position: absolute;
			top: 0;
			left: 0;
			transform: rotate(0);
		}

		.maplist {
			position: absolute;
			bottom: 10px;
			left: 50%;
			width: 460px;
			margin-left: -230px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.maplist>li {
			display: flex;
			flex-direction: column;
		}

		.maplist>li>p {
			text-align: center;
		}

		.maplist>li>img {
			width: 150px;
			height: auto;
			cursor: pointer;
		}
		.load {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1000000;
			pointer-events: auto;
		}
		.shaow {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgb(0, 0, 0);
			opacity: 0.6;
		}
		
		.banner {
			width: 100%;
			height: 100%;
		}
		
		.load-text {
			position: fixed;
			top: 50%;
			left: 50%;
			height: 80px;
			transform: translate(-50%, -60%);
			font-size: 24px;
			color: white;
		}
		.load-text .text{
			text-align: center;
			font-size: 28px;
			font-weight: 600;
			padding: 10px;
		}
		.load-progress{
			position: relative;
			width: 40vw;
		}
		.loadwidth{
			position: absolute;
			top: 8px;
			left: 7px;
			width:11%;
			overflow: hidden;
			height: 56px;
		}
		.load-progress .loadingbg{
			position: absolute;
			top: 0;
			left: 0;
			width: 40vw;
			height: 70px;
		}
		.loadingfill{
			width: calc(40vw - 15px);
			height: 56px;
		}
		.dialog-box{
			position: fixed;
			bottom: 30px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.dialog-box img{
			position: absolute;
			width: 350px;
			height:70px;
		}
		.dialog-box input{
			position: relative;
			z-index: 100;
			width:220px;
			height: 40px;
		}
	}
	@media screen and (max-width: 950px){
		.allbtn{
			position: absolute;
			top: 10px;
			right: 10px;
		}
		.allbtn div{
			background-color: white;
			color: #666;
			border-radius: 5px;
			padding: 5px 10px;
		}
		.blocks {
			width: 100%;
			height: 100%;
			/* background: url("../assets/background.jpg") no-repeat center; */
			background-size: cover;
			position: relative;
		}
		.tips {
			position: absolute;
			top: 5px;
			left: 50%;
			width: 360px;
			margin-left: -180px;
			color: #666;
		}
		
		.map_container {
			width: 120px;
			height: 120px;
			position: absolute;
			top: 0;
			left: 0;
		}
		
		.map {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		
		.figure {
			width: 8px;
			height: 16px;
			position: absolute;
			top: 0;
			left: 0;
			transform: rotate(0);
		}
		
		.maplist {
			position: absolute;
			bottom: 10px;
			left: 50%;
			width: 460px;
			margin-left: -230px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		
		.maplist>li {
			display: flex;
			flex-direction: column;
		}
		
		.maplist>li>p {
			text-align: center;
		}
		
		.maplist>li>img {
			width: 150px;
			height: auto;
			cursor: pointer;
		}
		.load {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1000000;
			pointer-events: auto;
		}
		.shaow {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgb(0, 0, 0);
			opacity: 0.6;
		}
		
		.banner {
			width: 100%;
			height: 100%;
		}
		
		.load-text {
			position: fixed;
			top: 50%;
			left: 50%;
			height: 80px;
			transform: translate(-50%, -60%);
			font-size: 24px;
			color: white;
		}
		.load-text .text{
			text-align: center;
			font-size: 28px;
			font-weight: 600;
			padding: 10px;
		}
		.load-progress{
			position: relative;
			width: 50vw;
		}
		.loadwidth{
			position: absolute;
			top: 5px;
			left: 5px;
			width:11%;
			overflow: hidden;
			height: 38px;
		}
		.load-progress .loadingbg{
			position: absolute;
			top: 0;
			left: 0;
			width: 50vw;
			height: 50px;
		}
		.loadingfill{
			width: calc(50vw - 10px);
			height: 38px;
		}
		.entergame{
			width: 210px;
			height: 70px;
		}
		.dialog-box{
			position: fixed;
			bottom: 10px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.dialog-box img{
			position: absolute;
			width: 210px;
			height:40px;
		}
		.dialog-box input{
			position: relative;
			z-index: 100;
			width:140px;
			height: 25px;
		}
	}
</style>